.adminParticipantItem {
    composes: item from '../../ContestParticipants/ContestParticipantsItem/contestParticipantsItem.module.scss';
    display: flex;
    flex-direction: row;
}

.itemLeft {
    composes: left from '../../ContestParticipants/ContestParticipantsItem/contestParticipantsItem.module.scss';
}

.right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.user {
    font-weight: 400;
    font-size: 1rem;
    color: var(--white_color);
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.userLeftControls {
    display: flex;
    gap: 10px;

    svg {
        cursor: pointer;
    }
}

.controls {
    display: flex;
    gap: 50px;
    margin-bottom: 10px;
}

.agree,
.cancel {
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;
    transition: 0.2s ease-in-out;

    &:active {
        transition: 0.2s ease-in-out;
        scale: 0.9;
    }
}

.agree {
    color: #91ff4e;
}

.cancel {
    color: var(--lalau_color);
}
