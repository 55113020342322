.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    backdrop-filter: blur(5px);
}

.header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 var(--page_x_padding);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 8;
    padding-top: 40px;
    padding-bottom: 20px;
    box-shadow: var(--black_color) 0px 102px 57px -80px inset;

    span {
        cursor: pointer;
    }

    p {
        color: var(--white_color);
        font-weight: 600;
        font-size: 1rem;
    }

    .headerLeft {
        display: flex;
        align-items: center;
        gap: 10px;
    }
}

.gallery {
    width: 100%;
    height: 100svh;
    overflow: hidden;
}

.galleryItem {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100svh;

    img {
        width: 100%;
        height: max-content;
    }
}
