.main {
  background-color: var(--white_color);
  border-radius: 20px;
  padding: 27px 30px;

  display: flex;
  justify-content: space-between;
}

.left, .right {
 
  p {
    color: var(--black_color);
    font-weight: 600;
    font-size: 0.875rem;
  }

  a {
    text-decoration: none;
    color: var(--black_color);
    font-weight: 400;
    font-size: 0.875rem;
    margin-top: 10px;
    display: block;
  }
}