.winner {
    background-color: var(--black_color);
    padding: 20px 10px;
    box-sizing: border-box;
    width: 100%;
    border-radius: 20px;

    display: flex;
    flex-direction: column;
    gap: 20px;
}

.top {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: -60px;
}

.user {
    border-radius: 15px;
    overflow: hidden;
    max-height: 170px;
    flex: 0.4;
    min-width: 115px;
    max-width: 196px;

    img {
        object-fit: cover;
        width: 100%;
        // height: 100%;
    }
}

.userInfo {
    flex: 0.5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 153px;
    max-width: 245px;
}

.prize {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;

    h3 {
        font-size: 1.15rem;
        color: var(--lalau_color);
        font-weight: 700;
    }
}

.statisticWinner {
    composes: top from '../ContestParticipantsItem/contestParticipantsItem.module.scss';

    align-items: center !important;
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;

    div {
        display: flex;
        align-items: flex-end;
        gap: 5px;

        p {
            color: var(--lalau_color);
            font-size: 1.625rem;
            font-weight: 800;
            text-transform: uppercase;
        }
    }
}

.percent {
    color: #91ff4e !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
}

.winnerPrizeTextWinner {
    composes: winnerPrizeText from '../ContestParticipantsItem/contestParticipantsItem.module.scss';

    font-size: 1.2rem !important;
    padding: 5px 15px;
}

.contestSponsorItem {
    composes: sponsorItem from '../../ContestSponsor/contestSponsor.module.scss';
    padding: 2px 8px;

    img {
        width: 35px;
    }
}

.winnerInfo {
    p {
        font-size: 1.375rem;
        font-weight: 400;
    }

    small {
        font-size: 0.625rem;
        font-weight: 700;
    }
}

.bottom {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
}

@media (max-width: 334px) {
    .top {
        justify-content: center;
    }

    .userInfo {
        flex: 1;
    }
}
