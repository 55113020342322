.sponsorItem {
  padding: 5px 8px;
  background-color: #F3F4F6;
  border-radius: 50px;
  display: flex;
  gap: 10px;
  align-items: center;
  width: fit-content;
  box-sizing: border-box;

  p {
    color: var(--black_color);
    font-size: 0.875rem;
    font-weight: 400;
  }
}